import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query($Slug: String) {
    sanityPost(slug: { current: { eq: $Slug } }) {
      title
      subTitle
      publishedAt
      _rawBody
      mainImage {
        alt
        image {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    allSanityServices {
      edges {
        node {
          title
          subtitle
          slug {
            current
          }
        }
      }
    }
  }
`

const Insights = props => {
  return (
    <Layout>
      <SEO
        title={props.data.sanityPost.title}
        // description={props.data.sanityPost.seo.meta_description}
        // image={props.data.sanityPost.mainImage.asset.fluid.src}
      />

      <section className="insight_subpage subpage">
        <Img
          fluid={props.data.sanityPost.mainImage.image.asset.fluid}
          alt={props.data.sanityPost.mainImage.alt}
        ></Img>

        <div className="text_content">
          <h1>{props.data.sanityPost.title}</h1>
          <p className="date">Udgivet: {props.data.sanityPost.publishedAt}</p>
          <div>
            <p className="subtitle">{props.data.sanityPost.subTitle}</p>

            <BlockContent
              className={props.data.sanityPost.templateList}
              blocks={props.data.sanityPost._rawBody}
              projectId="ppd47c0u"
              dataset="production"
              serializers={serializers}
            />
          </div>
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default Insights
